import React from 'react';
import classNames from 'classnames';
import { ActiveAnimation, ActiveAnimationType } from 'components/template-designer/types/animation.type';
import TemplateDesignerStore from 'components/template-designer/data/template-designer-store';
import Layer from 'components/template-designer/types/layer.type';
import { RightSidebarTab, State } from 'components/template-designer/types/template.type';
import { CustomAndPredefinedAnimations } from 'components/template-designer/types/layerProperties.type';
import { TimelineHelpers } from 'components/template-designer/helpers/timeline.helpers';
import '../styles/keyframe-dot.scss';

interface Props {
    className?: string;
    dragRef?: React.RefObject<HTMLDivElement>;
    left: number;
    stamp?: number;
    isSelected?: boolean;
    isActive?: boolean;
    isKeyframeActive?: boolean;
    parentSelected?: boolean;
    hasOverwrite?: boolean;
    showDragHand?: boolean;
    layer?: Layer;
    keyframeKey?: Animation['id'];
    animationKey?: keyof CustomAndPredefinedAnimations;
    dataCyPrefix?: string;
}

const KeyframeDot = ({
    className,
    dragRef,
    left,
    stamp,
    isSelected,
    isActive,
    isKeyframeActive,
    parentSelected,
    hasOverwrite,
    showDragHand = true,
    layer,
    keyframeKey,
    animationKey,
    dataCyPrefix
}: Props): JSX.Element => {
    /**
     * Activate the keyframe.
     */
    const activateKeyframe = () => {
        if (!layer || !keyframeKey || !animationKey) return;

        const newActiveAnimations: State['activeAnimations'] = [
            {
                keyframeKey: keyframeKey,
                animationKey: animationKey,
                type: ActiveAnimationType.Keyframe,
                layer: layer.key
            }
        ];

        TemplateDesignerStore.save(
            [
                ['state.activeAnimations', newActiveAnimations],
                ['view.showTab', RightSidebarTab.Animate],
                ['state.selectedLayers', [layer]]
            ],
            {
                saveToHistory: false
            }
        );

        if (stamp !== undefined) {
            const time = TimelineHelpers.stampToSeconds(stamp);
            TimelineHelpers.seekTo(time);
        }
    };

    const animationData: ActiveAnimation | null = (() => {
        if (!layer || !keyframeKey || !animationKey) return null;
        return { type: ActiveAnimationType.Keyframe, layer: layer.key, animationKey, keyframeKey: keyframeKey };
    })();

    return (
        <div
            ref={dragRef}
            className={classNames(
                'template-designer__timeline-keyframe-dot',
                'template-designer__timeline__selecto-target',
                {
                    'template-designer__timeline-keyframe-dot--drag-hand': showDragHand,
                    'template-designer__timeline-keyframe-dot--active': isActive && isSelected && !hasOverwrite,
                    'template-designer__timeline-keyframe-dot--keyframe-active': isKeyframeActive,
                    'template-designer__timeline-keyframe-dot--has-overwrite': isSelected && hasOverwrite,
                    'template-designer__timeline-keyframe-dot--parent-selected':
                        (parentSelected || isSelected) && !isActive && !isKeyframeActive && !hasOverwrite,
                    'template-designer__timeline-keyframe-dot--parent-selected-overwrite':
                        (parentSelected || isSelected) && !isActive && !isKeyframeActive && hasOverwrite
                },
                className
            )}
            onMouseDown={activateKeyframe}
            style={{ left: `${left}px` }}
            data-cy={`${dataCyPrefix}-dot`}
            data-type={animationData?.type}
            data-layer={animationData?.layer}
            data-animationkey={animationData?.animationKey}
            data-keyframekey={animationData?.keyframeKey}
        />
    );
};

export { KeyframeDot };
