import React, { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from 'components/ui-components-v2/Checkbox';
import List from 'components/ui-components-v2/List';
import ListItem from 'components/ui-components-v2/ListItem';
import Translation from 'components/data/Translation';
import Format from 'components/template-designer/types/format.type';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import { View } from 'components/template-designer/types/template.type';
import TemplateDesignerStore, { MultiModel } from 'components/template-designer/data/template-designer-store';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import Dialog from 'components/ui-components/Dialog';
import '../styles/format-management-copy-dialog.scss';
import Typography from 'components/ui-components-v2/Typography';

interface Props {
    onClose: () => void;
    formats: Format[];
    formatToCopy: Format['key'];
    frameType: View['frameType'];
}

/**
 * Format duplicate dialog
 * The component displays a dialog to copy format styling to another format.
 */
const FormatManagementCopyToDialog = ({ onClose, formats, formatToCopy, frameType }: Props) => {
    const [selectedFormats, setSelectedFormats] = useState<Format['key'][]>([]);

    /**
     * Handle save action. Copy format styling to selected formats.
     */
    const handleSave = () => {
        const formatPropertiesToCopy = getTemplateData(`layerProperties.${formatToCopy}.${frameType}`);

        const changes: MultiModel = [];

        selectedFormats.forEach((selectedFormat) => {
            changes.push([`layerProperties.${selectedFormat}.${frameType}`, cloneDeep(formatPropertiesToCopy)]);
        });

        TemplateDesignerStore.save(changes);
        onClose();
    };

    /**
     * Handle format selection. Add or remove format from selected formats.
     * @param formatKey - Selected format key.
     */
    const handleFormatSelection = (formatKey: Format['key']) => {
        setSelectedFormats((prevState) => {
            const isAlreadyAdded = prevState.includes(formatKey);

            if (!isAlreadyAdded) {
                return [...prevState, formatKey];
            }

            return prevState.filter((key) => key !== formatKey);
        });
    };

    return (
        <Dialog
            open
            maxWidth="xl"
            onClose={onClose}
            title={Translation.get('sidebarLeft.formats.dialogs.duplicate.title', 'template-designer')}
            onConfirm={handleSave}
            confirmText={Translation.get('sidebarLeft.formats.actions.copyFormatStyling', 'template-designer').toUpperCase()}>
            <List dense disablePadding>
                {formats?.map((item) => (
                    <ListItem key={item.key} disablePadding disableGutters divider={false} className="template-designer__copy-format-dialog__list-item">
                        <ListItemButton
                            role={undefined}
                            dense
                            className={selectedFormats.includes(item.key) ? 'template-designer__copy-format-dialog__list-item--selected' : ''}
                            onClick={() => handleFormatSelection(item.key)}>
                            <ListItemIcon>
                                <Checkbox edge="start" checked={selectedFormats.includes(item.key)} id={item.key + '_duplicate'} disableRipple size="small" />
                            </ListItemIcon>
                            <Typography>{item.title}</Typography>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
};

export default FormatManagementCopyToDialog;
